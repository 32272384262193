import React from 'react';
import {Alert, Button, Form, Input, message as antMessage, message, Modal, Space, Table, Typography} from 'antd';

import Money from '../../components/Money';
import axios from "../../request/axios";
import FormRegionTitle from "../../components/FormRegionTitle";

const {Link, Paragraph, Text} = Typography;

class AliTransModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accounts: [],
            loading: false,
            visible: false,
            submitting: false
        }
    }

    openModal = () => {
        this.setState({loading: true, visible: true, submitting: false});
        axios.get('charge-balance/alipay_collection_accounts').then((res) => {
            const {data} = res.data;
            this.setState({accounts: data});
        }).finally(() => {
            this.setState({loading: false});
        });
    };

    closeModal = () => {
        this.setState({accounts: [], visible: false});
    };

    onFinish = (values) => {
        this.setState({submitting: true});
        axios.post('charge-balance/ali_trans', values).then(res => {
            const {code, message} = res.data;
            if (code === 0) {
                antMessage.success('加款成功');
            } else {
                antMessage.error('加款失败：' + message);
            }
        }).finally(() => {
            this.setState({submitting: false});
        });
    };

    render() {
        const footer = <Space>
            <Button onClick={this.closeModal}>取消</Button>
        </Space>

        return <>
            <Modal
                title='支付宝转账'
                footer={footer}
                visible={this.state.visible}
                onCancel={this.closeModal}
                width={600}
                centered={true}
            >
                <Table rowKey='id' dataSource={this.state.accounts} loading={this.state.loading}
                       pagination={false} scroll={{scrollToFirstRowOnChange: true}}>
                    {/*sticky={{offsetHeader: 56}}*/}
                    <Table.Column title='支付宝收款账号' dataIndex='alipay_account' key='alipay_account' width={80}/>
                    <Table.Column title='姓名' dataIndex='alipay_account_name' key='alipay_account_name' width={80}/>
                </Table>
                <Form ref={ref => this.formRef = ref} onFinish={this.onFinish} labelCol={{span: 6}}
                      style={{width: 700, margin: 'auto'}} wrapperCol={{span: 15}}>
                    {
                        !this.state.loading && <div>
                            <div className='my-4' style={{width: 550}}>
                                <Alert icon message={<Text>
                                    <Text>1. 转账至以上账号后，<Text type='danger' strong>1个小时内</Text>在下方填入支付宝订单号即可充值，超出1个小时候需人工核实处理；<br/></Text>
                                    <Text>2. 请添加好友再转账，降低风控风险；<br/></Text>
                                    <Text type='danger' strong>3. 收款账号可能随时变更，请以上方账号为准，确认后再转账。</Text>
                                </Text>} type='error'/>
                            </div>
                            <Form.Item
                                name='alipay_order_no'
                                label='支付宝订单号'
                                style={{marginBottom: 12}}
                                onChange={this.onAmountChange}
                                extra='请填写转账的支付宝订单号'
                                rules={[
                                    {
                                        required: true
                                    }
                                ]}
                            >
                                <Input style={{width: 220}}/>
                            </Form.Item>

                            <Form.Item
                                style={{marginBottom: 12}}
                                label='手续费'
                            >
                                免手续费
                            </Form.Item>

                            <Form.Item wrapperCol={{
                                offset: 6,
                                span: 10
                            }}>
                                <Button type='primary' htmlType='submit' loading={this.state.submitting}>
                                    已转账，通知加款
                                </Button>
                            </Form.Item>
                        </div>
                    }
                </Form>
            </Modal>
        </>
    }
}

export default AliTransModal;