import {Alert, Button, Card, Form, Input, message as antMessage, Modal, Skeleton, Space, Typography} from 'antd';
import React from 'react';
import axios from '../../request/axios';

import style from './asserts/ChargeBalance.module.less';
import wechat_pay from './asserts/wechat_pay.png';
import alipay from './asserts/alipay.png';
import bank_pay from './asserts/bank_pay.png';
import tran_pay from './asserts/tran_pay.png';
import taobao from './../../asserts/imgs/shop-logo/taobao2.jpg'
import pinduoduo from './../../asserts/imgs/shop-logo/pinduoduo2.jpg'
import tianmao from './../../asserts/imgs/shop-logo/tianmao2.jpg'
import jingdong from './../../asserts/imgs/shop-logo/jingdong2.jpg'
import FormRegionTitle from '../../components/FormRegionTitle';
import Money from '../../components/Money';
import YouyuUtils from '../../utils/YouyuUtils';
import WechatPayModal from './WechatPayModal';
import AliTransModal from './AliTransModal';

const {Text} = Typography;

class ChargeBalance extends React.Component {
  default_amount = 1000;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      settings: null,
      selected_channel: null,
      taobao_channels: [],
      amount: this.default_amount,
      charge_fee_rate: null,
      charge_fee: null,
      submitting: false
    };
  }

  componentDidMount() {
    this.setState({loading: true});
    axios.get('charge-balance/settings').then(res => {
      const {data} = res.data;
      this.setState({settings: data}, () => {
        this.parseTaobaoChannels();
        this.selectDefaultChannel();
      });
    }).finally(() => {
      this.setState({loading: false});
    });
  }

  parseTaobaoChannels = () => {
    let taobao_channels = [];
    const {taobao_shop_url} = this.state.settings;
    try {
      const list = JSON.parse(taobao_shop_url);
      if(Array.isArray(list)) {
        list.forEach(item => {
          taobao_channels.push({
            face_value: item.face_value,
            url: item.url
          })
        })
      } else {
        taobao_channels.push({
          face_value: null,
          url: taobao_shop_url
        });
      }
    } catch (e) {
      taobao_channels.push({
        face_value: null,
        url: taobao_shop_url
      });
    }
    this.setState({
      taobao_channels
    });
    console.log(taobao_channels);
  }

  onFinish = (values) => {
    this.setState({submitting: true});
    if (this.state.selected_channel === 'wechat') {
      axios.post('charge-balance/wechat', {amount: values.amount}).then(res => {
        const {code, message, data} = res.data;
        if (code === 0) {
          this.wechatPayModal.openModal(data);
        } else {
          antMessage.error(message);
        }
      }).finally(() => {
        this.setState({submitting: false});
      });
    }
    if (this.state.selected_channel === 'alipay') {
      axios.post('charge-balance/alipay', {amount: values.amount}).then(res => {
        const {code, message, data} = res.data;
        if (code === 0) {
          window.open(data, '_blank');
        } else {
          antMessage.error(message);
        }
      }).finally(() => {
        this.setState({submitting: false});
      });
    }
  };

  gotoShop = (channel, url = null) => {
    const {settings} = this.state;
    let shop_type;

    if (channel === 'taobao') {
      shop_type = '淘宝店铺';
      url = url ?? settings.taobao_shop_url;
    } else if (channel === 'tianmao') {
      shop_type = '天猫';
      url = settings.tianmao_shop_url;
    } else if (channel === 'jingdong') {
      shop_type = '京东';
      url = settings.jingdong_shop_url;
    }

    if (channel === 'pinduoduo') {
      Modal.info({
        title: settings.pinduoduo_shop_charge_desc,
        ...YouyuUtils.confirmModalDefaultProps
      });
    } else {
      Modal.confirm({
        title: `即将跳转到${shop_type}，请在购买加款卡时备注“商家编号”，付款后平台内冻结资金自动加款，确认收货后解冻为余额，详情请见宝贝说明`,
        ...YouyuUtils.confirmModalDefaultProps,
        onOk: () => {
          window.open(url, '_blank');
        },
        okText: '跳转'
      });
    }
  }

  // 计算手续费
  computeChargeFee = () => {
    const {amount, charge_fee_rate} = this.state;
    let charge_fee;
    if (amount === null || isNaN(amount) || charge_fee_rate === null) {
      charge_fee = null;
    } else {
      if (charge_fee_rate === 0)
        charge_fee = 0;
      else {
        let total_fee = Math.ceil(amount * (100 + charge_fee_rate)) / 100;
        charge_fee = total_fee - amount;
        // charge_fee = (amount * charge_fee_rate / 100).toFixed(2);
      }
    }
    this.setState({charge_fee});
  }

  onAmountChange = (e) => {
    this.setState({amount: e.target.value}, this.computeChargeFee);
  }

  // 选择默认支付方式
  selectDefaultChannel = () => {
    const {settings} = this.state;
    if (settings.payment_channels.includes('wechat')) {
      this.selectChannel('wechat');
    } else if (settings.payment_channels.includes('alipay')) {
      this.selectChannel('alipay');
    }
  }

  // 选择支付方式
  selectChannel = (channel) => {
    this.setState({selected_channel: channel});
    if (channel === 'wechat') {
      this.setState({
        charge_fee_rate: this.state.settings.wechat_charge_fee_rate
      }, this.computeChargeFee);
    } else if (channel === 'alipay') {
      this.setState({
        charge_fee_rate: this.state.settings.alipay_charge_fee_rate
      }, this.computeChargeFee);
    }else{
      this.setState({
        charge_fee_rate: 0
      }, this.computeChargeFee);
    }
  }

  chargeFeeRateText = (rate) => {
    let text;
    if (Math.round(rate * 100) === 0) {
      text = '免手续费';
    } else {
      text = '手续费' + rate + '%';
    }
    return text;
  }

  render() {
    const {amount, settings, taobao_channels, selected_channel, charge_fee_rate, charge_fee} = this.state;

    return (
        <Card title='余额充值'>
          {
            this.state.settings !== null
                ?
                <div>
                  <Form ref={ref => this.formRef = ref} onFinish={this.onFinish} labelCol={{span: 6}}
                        style={{width: 700, margin: 'auto'}} wrapperCol={{span: 15}}>

                    {
                        settings.charge_balance_page_alert !== null && settings.charge_balance_page_alert !== '' &&
                        <div className='mb-4'><Alert type='warning'
                                                     message={settings.charge_balance_page_alert}/></div>
                    }

                    <FormRegionTitle>充值方式</FormRegionTitle>
                    <Space size='large' wrap>
                      {
                          settings.payment_channels.includes('wechat') &&
                          <div
                              className={[style.channelCard, selected_channel === 'wechat' ? style.selected : ''].join(' ')}
                              onClick={() => this.selectChannel('wechat')}
                          >
                            <img src={wechat_pay} alt='logo'/>
                            <div>{this.chargeFeeRateText(settings.wechat_charge_fee_rate)}</div>
                          </div>
                      }
                      {
                          settings.payment_channels.includes('alipay') &&
                          <div
                              className={[style.channelCard, selected_channel === 'alipay' ? style.selected : ''].join(' ')}
                              onClick={() => this.selectChannel('alipay')}
                          >
                            <img src={alipay} alt='logo'/>
                            <div>在线支付 {this.chargeFeeRateText(settings.alipay_charge_fee_rate)}</div>
                          </div>
                      }
                      {
                          settings.payment_channels.includes('taobao') && taobao_channels.map(item => {
                            return <div key={item.face_value} className={style.channelCard}
                                        onClick={() => this.gotoShop('taobao', item.url)}>
                              <img src={taobao} alt='logo'/>
                              <div>{item.face_value} {this.chargeFeeRateText(settings.taobao_charge_fee_rate)}</div>
                            </div>
                          })
                      }
                      {
                          settings.payment_channels.includes('pinduoduo') &&
                          <div className={style.channelCard} onClick={() => this.gotoShop('pinduoduo')}>
                            <img src={pinduoduo} alt='logo'/>
                            <div>{this.chargeFeeRateText(0)}</div>
                          </div>
                      }
                      {
                          settings.payment_channels.includes('tianmao') &&
                          <div className={style.channelCard} onClick={() => this.gotoShop('tianmao')}>
                            <img src={tianmao} alt='logo'/>
                            <div>{this.chargeFeeRateText(0)}</div>
                          </div>
                      }
                      {
                          settings.payment_channels.includes('jingdong') &&
                          <div className={style.channelCard} onClick={() => this.gotoShop('jingdong')}>
                            <img src={jingdong} alt='logo'/>
                            <div>{this.chargeFeeRateText(0)}</div>
                          </div>
                      }
                      {
                        settings.payment_channels.includes('mb_bank') &&
                        <div
                          className={[style.channelCard, selected_channel === 'mb_bank' ? style.selected : ''].join(' ')}
                          onClick={() => this.selectChannel('mb_bank')}
                        >
                          <img src={bank_pay} alt='logo'/>
                          <div>{this.chargeFeeRateText(0)}</div>
                        </div>
                      }
                      {
                        settings.payment_channels.includes('mb_tran') &&
                        <div
                          className={[style.channelCard, selected_channel === 'mb_tran' ? style.selected : ''].join(' ')}
                          onClick={() => this.selectChannel('mb_tran')}
                        >
                          <img src={tran_pay} alt='logo'/>
                          <div>{this.chargeFeeRateText(0)}</div>
                        </div>
                      }
                      {
                        settings.payment_channels.includes('ali_trans') &&
                        <div className={style.channelCard} onClick={() => this.aliTransModal.openModal()}>
                          <img src={alipay} alt='logo'/>
                          <div>转账 {this.chargeFeeRateText(0)}</div>
                        </div>
                      }
                    </Space>
                    {
                        this.state.selected_channel && <div>
                          <div className='mt-4'>
                            <FormRegionTitle>充值金额</FormRegionTitle>
                          </div>
                          <Form.Item
                              name='amount'
                              initialValue={this.default_amount}
                              label='充值金额'
                              style={{marginBottom: 12}}
                              onChange={this.onAmountChange}
                              extra={Math.round(settings.minimum_charge_amount * 100) === 0 ? '' : ('最低充值金额' + settings.minimum_charge_amount + '元')}
                              rules={[
                                {
                                  required: true
                                },
                                ({getFieldValue}) => ({
                                  validator(_, value) {
                                    if (!value || (!isNaN(value))) {
                                      if (parseFloat(value) <= 0) {
                                        return Promise.reject(new Error('请输入正确的金额'));
                                      }
                                      if (parseFloat(value) < settings.minimum_charge_amount) {
                                        return Promise.reject(new Error('最低充值金额' + settings.minimum_charge_amount + '元'));
                                      }
                                      return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('请输入正确的金额'));
                                  }
                                })
                              ]}
                          >
                            <Input style={{width: 130, textAlign: 'right'}} suffix='元'/>
                          </Form.Item>

                          <Form.Item
                              style={{marginBottom: 12}}
                              label='手续费'
                          >
                            {
                                charge_fee_rate === 0 && '免手续费'
                            }
                            {
                                charge_fee_rate > 0 &&
                                <Text>{charge_fee_rate}%</Text>
                            }
                          </Form.Item>

                          <Form.Item
                              style={{marginBottom: 16}}
                              label='实际支付金额'
                          >
                            <Money value={(parseFloat(amount) + parseFloat(charge_fee)).toFixed(2)}/>
                          </Form.Item>

                          <Form.Item wrapperCol={{
                            offset: 6,
                            span: 10
                          }}>
                            <Button type='primary' htmlType='submit' disabled={this.state.selected_channel == null}
                                    loading={this.state.submitting}>
                              前往充值
                            </Button>
                          </Form.Item>
                        </div>
                    }
                  </Form>
                </div>
                :
                <div style={{width: 800, margin: '24px auto 24px', height: 400}}><Skeleton active
                                                                                           paragraph={{rows: 10}}/>
                </div>
          }
          <WechatPayModal ref={ref => this.wechatPayModal = ref}/>
          <AliTransModal ref={ref => this.aliTransModal = ref}/>
        </Card>
    );
  }
}

export default ChargeBalance;